<template>
	<div class="page-conference-detail">
		<el-card>
			<div class="inner-box">
				<div class="list-item">
					<div class="name" :class="{
            'will': conferenceDetail.conferenceState == 0,
            'doing': conferenceDetail.conferenceState == 1,
            'finished': conferenceDetail.conferenceState == 2
          }">{{conferenceDetail.conferenceTitle}}</div>
					<div class="detail">
						<div class="item date">举办时间：{{conferenceDetail._holdStartTime}}</div>
						<div class="item address">报名截止时间：{{conferenceDetail._signUpEndTime}}</div>
					</div>
					<div class="time" :class="{
            'active': conferenceDetail.conferenceState == 1
          }">{{conferenceDetail.timeStr}}</div>
					<div v-if="conferenceDetail.conferenceState == 1 && hasSignUpFlag" class="btn disabled">已报名</div>
					<div v-if="conferenceDetail.conferenceState == 1 && !hasSignUpFlag" class="btn"
						@click="showSignUpDailog"> 立即报名</div>
				</div>
				<div class="detail-box">
					<div class="custom-title">举办时间</div>
					<div class="module">时间：{{conferenceDetail._holdStartTime}}</div>
					<div class="custom-title">举办地点</div>
					<div class="module">地址：{{conferenceDetail.holdLocation}}</div>
					<div class="custom-title">重点招商类目：<span v-for="c in conferenceDetail.caList" :key="c.firstCaId"
							class="separator">{{c.firstCaName}} </span></div>
					<div class="module">
						<div v-for="c in conferenceDetail.caList" :key="c.firstCaId">{{c.firstCaName}}：{{c.caGroupName}}
						</div>
					</div>
					<div class="custom-title">招商会信息</div>
					<div class="module">
						<div class="label">优惠政策：</div>
						<div class="content" v-html="conferenceDetail._preferentialPolicy"></div>
					</div>
					<div class="module">
						<div class="label">招商会流程：</div>
						<div class="content" v-html="conferenceDetail._conferenceProcess"></div>
					</div>
					<div class="module">
						<div class="label">招商联系人：</div>
						<div class="content" v-html="conferenceDetail._linkmanName"></div>
					</div>
				</div>
			</div>
		</el-card>

		<el-dialog :visible.sync="signUpDailogFlag" :close-on-click-modal="false" :show-close="false" width="50%"
			title="报名信息填写" top="6vh" @closed="resetConferenceSignUpForm">
			<div class="sign">填写以下信息，提交报名</div>
			<el-form class="conference-sign-up-form" ref="conferenceSignUpForm" :model="conferenceSignUpForm"
				:rules="conferenceSignUpFormRules" label-width="100px">
				<el-form-item label="登记方式" prop="signupType">
					<el-radio-group v-model="conferenceSignUpForm.signupType" size="medium">
						<el-radio label="1">营业执照号</el-radio>
						<el-radio label="2">公司注册地</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="营业执照号" prop="creditCode" v-if="conferenceSignUpForm.signupType == 1">
					<el-input v-model.trim="conferenceSignUpForm.creditCode" placeholder="请输入15位或18位营业执照号"
						maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="公司名称" prop="companyName">
					<el-input v-model.trim="conferenceSignUpForm.companyName" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="品牌" prop="brand">
					<el-input v-model.trim="conferenceSignUpForm.brand" placeholder="请输入品牌" maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="年销售额" prop="annualSales">
					<el-select style="width:100%" v-model="conferenceSignUpForm.annualSales" placeholder="请选择">
						<el-option v-for="t in annualSalesOptions" :key="t.value" :label="t.label" :value="t.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入驻联系人" prop="coLinkman">
					<el-input v-model.trim="conferenceSignUpForm.coLinkman" placeholder="请输入入驻联系人" maxlength="50">
					</el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="coLinkmanPhone">
					<el-input v-model.trim="conferenceSignUpForm.coLinkmanPhone" placeholder="请输入联系电话" maxlength="11">
					</el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="coLinkmanMail">
					<el-input v-model.trim="conferenceSignUpForm.coLinkmanMail" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item label="入驻类目" prop="_bizFormCode">
					<el-cascader style="width:100%" placeholder="请选择" v-model="conferenceSignUpForm._bizFormCode"
						popper-class="custom-cascader" :options="bizformDictionaryOptions"
						:props="bizformDictionaryProps" clearable>
					</el-cascader>
				</el-form-item>
				<el-form-item label="期望和问题" prop="questions">
					<el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6}" maxlength="200"
						v-model.trim="conferenceSignUpForm.questions">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<div class="custom-action-box">
					<el-button class="btn reset" type="primary" :loading="submitPendingFlag"
						@click="signUpDailogFlag=false">
						取消
					</el-button>
					<el-button class="btn" type="primary" :loading="submitPendingFlag"
						@click="submitConferenceSignUpForm">
						确定
					</el-button>
				</div>
			</span>
		</el-dialog>

		<!-- 登录 -->
		<Login v-if="loginIsShow" :loginIsShow="loginIsShow" @loginVisible="loginVisible" />
	</div>
</template>

<script>
import {
	getConferenceDetail,
	geConferenceSignUpStatus,
	getBizformDictionary,
	submitConferenceSignUpForm,
} from "@/api/api-join-mall";
import Login from "@/components/Login.vue";
export default {
	components: {
		Login
	},
	data() {
		return {
			// 登录
			loginIsShow: false,
			// 是否已报名
			hasSignUpFlag: false,
			// 详情
			conferenceDetail: {
				_preferentialPolicy: "",
				_conferenceProcess: "",
				_linkmanName: "",
			},
			// 选择器 - 经营类目
			bizformDictionaryProps: {
				multiple: true,
				value: "code",
				label: "name",
				children: "childCat",
				// checkStrictly: true // 选择任意级
			},
			bizformDictionaryOptions: [],
			// 选择器 - 年销售额
			annualSalesOptions: [
				{
					value: 1,
					label: "50万以下",
				},
				{
					value: 2,
					label: "50-100万",
				},
				{
					value: 3,
					label: "100万以上",
				},
			],
			// 报名弹窗
			signUpDailogFlag: false,
			conferenceSignUpForm: {
				signupType: "1", // 1-营业执照号 2-公司注册地
				creditCode: "",
				companyName: "",
				brand: "",
				annualSales: "",
				coLinkman: "",
				coLinkmanPhone: "",
				coLinkmanMail: "",
				_bizFormCode: [],
				bizFormCode: "",
				questions: "",
			},
			conferenceSignUpFormRules: {
				signupType: [
					{
						required: true,
						message: "请选择登记方式",
						trigger: ["change", "trigger"],
					},
				],
				creditCode: [
					{
						required: true,
						message: "请输入营业执照号",
						trigger: "blur",
					},
					{
						pattern: /^[A-Za-z0-9]+$/,
						message: "仅支持英文和数字",
						trigger: "blur",
					},
				],
				companyName: [
					{
						required: true,
						message: "请输入公司名称",
						trigger: "blur",
					},
					{
						pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
						message: "不能包含特殊字符",
						trigger: "blur",
					},
				],
				brand: [
					{
						required: true,
						message: "请输入品牌",
						trigger: "blur",
					},
					{
						pattern: /^[\u0391-\uFFE5A-Za-z]+$/,
						message: "仅支持中文和英文",
						trigger: "blur",
					},
				],
				annualSales: [
					{
						required: true,
						message: "请选择年销售额",
						trigger: ["change", "blur"],
					},
				],
				coLinkman: [
					{
						required: true,
						message: "请输入入驻联系人",
						trigger: "blur",
					},
				],
				coLinkmanPhone: [
					{
						required: true,
						message: "请输入联系电话",
						trigger: "blur",
					},
					{ max: 11, message: "最多输入11个字符", trigger: "blur" },
					{
						pattern:
							/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
						message: "请输入有效的手机号码",
						trigger: ["blur"],
					},
				],
				_bizFormCode: [
					{
						required: true,
						message: "请选择入驻类目",
						trigger: "blur",
					},
				],
				coLinkmanMail: [
					{
						required: true,
						message: "请输入邮箱",
						trigger: "blur",
					},
					{
						pattern:
							/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: "请输入正确的邮箱格式",
						trigger: ["blur"],
					},
					{ max: 50, message: "最多输入50位字符", trigger: "change" },
				],
			},
			submitPendingFlag: false,
		};
	},
	methods: {
		_formatDate2local(str, startIndex) {
			let dateArr = str.split(" ")[0].split("-"),
				dateUnit = ["年", "月", "日"],
				result = "";
			dateArr.forEach((el, index) => {
				if (index >= startIndex) {
					result += Number(el) + dateUnit[index];
				}
			});
			return result;
		},
		_formatStr2html(str) {
			if (!str) return;
			return str.replace(/\r\n/g, "<br/>");
		},
		/**
		 * 招商会 - 详情
		 */
		getConferenceDetail() {
			let { id } = this.$route.query;
			getConferenceDetail(id).then((res) => {
				if (res.data.code === 0) {
					let temp = res.data.data;
					temp.timeStr = this._formatDate2local(
						temp.holdStartTime,
						1
					);
					temp._holdStartTime = this._formatDate2local(
						temp.holdStartTime,
						0
					);
					temp._signUpEndTime = this._formatDate2local(
						temp.signUpEndTime,
						0
					);
					for (let key in this.conferenceDetail) {
						if (key.includes("_")) {
							temp[key] = this._formatStr2html(
								temp[key.split("_")[1]]
							);
						}
					}
					this.conferenceDetail = temp;
				}
			});
		},
		/**
		 * 招商会 - 报名状态
		 */
		geConferenceSignUpStatus() {
			geConferenceSignUpStatus(this.$route.query.id).then((res) => {
				if (res.data.code === 0) {
					// 1-未报名 2-报名
					this.hasSignUpFlag = res.data.data == 1 ? false : true;
				}
			});
		},
		/**
		 * 获取经营类目
		 */
		getBizformDictionary() {
			getBizformDictionary().then((res) => {
				if (res.data.code === 0) {
					let temp = res.data.data || [];
					this.bizformDictionaryOptions = temp;
				}
			});
		},
		/**
		 * 招商会 - 立即报名
		 */
		showSignUpDailog() {
			if (sessionStorage.getItem("user_token")) {
				this.signUpDailogFlag = true;
			} else {
				this.loginIsShow = true;
			}
		},
		resetConferenceSignUpForm() {
			this.$refs.conferenceSignUpForm.resetFields();
		},
		submitConferenceSignUpForm() {
			this.$refs.conferenceSignUpForm.validate((valid) => {
				if (valid) {
					let conferenceSignUpForm = JSON.parse(
						JSON.stringify(this.conferenceSignUpForm)
					);
					conferenceSignUpForm.bizFormCode = JSON.stringify(
						conferenceSignUpForm._bizFormCode
					);
					delete conferenceSignUpForm._bizFormCode;
					conferenceSignUpForm.conferenceId = this.$route.query.id;
					this.submitPendingFlag = true;
					submitConferenceSignUpForm(conferenceSignUpForm)
						.then((res) => {
							if (res.data.code === 0) {
								this.geConferenceSignUpStatus();
							}
							this.signUpDailogFlag = false;
							this.submitPendingFlag = false;
						})
						.catch(() => {
							this.submitPendingFlag = false;
						});
				}
			});
		},
		/**
		 * 登录回调
		 */
		loginVisible(status) {
			this.loginIsShow = status;
			this.geConferenceSignUpStatus();
		},
	},
	created() {
		if (sessionStorage.getItem("user_token")) this.geConferenceSignUpStatus();
		this.getConferenceDetail();
		this.getBizformDictionary();
	},
};
</script>

<style lang="scss" scoped>
@import "../../mall/assets/styles/base.scss";
.page-conference-detail {
	.inner-box {
		padding: 48px 40px;
		.list-item {
			position: relative;
			font-size: 12px;
			color: #666666;
			padding: 47px 177px 33px;
			margin-bottom: 28px;
			border-radius: 10px;
			background-color: #fafafa;
			transition: box-shadow 0.3s linear;
			&:last-of-type {
				margin-bottom: 0;
			}
			.name {
				position: relative;
				display: inline-block;
				font-size: 18px;
				color: #333333;
				margin-bottom: 26px;
				&::after {
					position: absolute;
					right: -90px;
					width: 70px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					font-size: 14px;
					border-radius: 50px;
					color: #ffffff;
					background-color: #b0b0b0;
				}
				&.will::after {
					content: "未开始";
				}
				&.doing::after {
					content: "进行中";
					color: #333333;
					background-color: #fbd5a9;
				}
				&.finished::after {
					content: "已结束";
				}
			}
			.detail {
				.item {
					display: inline-block;
					margin-right: 40px;
				}
			}
			.time {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 120px;
				height: 38px;
				line-height: 38px;
				font-size: 18px;
				color: #ffffff;
				text-align: center;
				margin: auto 0;
				background-color: #b0b0b0;
				&.active {
					background: linear-gradient(to right, #ff4a2d, #ff8600);
				}
			}
			.btn {
				position: absolute;
				top: 0;
				right: 60px;
				bottom: 0;
				width: 147px;
				height: 42px;
				line-height: 42px;
				margin: auto 0;
				text-align: center;
				font-size: 14px;
				color: #ffffff;
				border-radius: 50px;
				background: linear-gradient(to left, #ff4a2d, #ff8600);
				box-shadow: 0px 5px 15px 0px rgba(255, 84, 37, 0.3);
				cursor: pointer;
				&.disabled {
					background: #b0b0b0;
					box-shadow: none;
				}
			}
		}
		.module {
			font-size: 14px;
			color: #666666;
			margin: 20px 0;
		}
		.separator {
			position: relative;
			margin-right: 20px;
			&::after {
				content: "、";
				position: absolute;
				right: -20px;
				bottom: -2px;
			}
			&:last-of-type::after {
				content: "";
			}
		}
	}
	.sign {
		position: absolute;
		top: 19px;
		right: 42px;
		font-size: 14px;
		color: $color-theme;
	}
	.conference-sign-up-form {
		padding: 0 100px;
	}
	.dialog-footer {
		.custom-action-box {
			margin-top: 30px;
			margin-bottom: 40px;
			.reset {
				background: #ffffff !important;
			}
		}
	}
}
</style>